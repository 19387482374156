import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Email, Section, Text, Title } from "../../components/Core";
import { device } from "../../utils";

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 80px;
  padding-right: 80px;
  @media ${device.lg} {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media ${device.xl} {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

const ContactInfo = () => {

  return (
    <>
      <Section className="py-0">
        <Container>
          <Row className="justify-content-center mt-5 pt-lg-5">
            <Col xl="10">
              <WidgetWrapper>
                <Row>
                  <Box className="mb-5">
                    <Title variant="card" fontSize="24px">
                      Information
                    </Title>
                    <Text>
                      If you have a question for us, drop us an email at
                      {" "}<Email address="hello@opqo.io" />{" "}
                      and we'll get back to you within a day.
                    </Text>
                  </Box>
                </Row>
                <Row>
                  <Box className="mb-5">
                    <Title variant="card" fontSize="24px">
                      Support
                    </Title>
                    <Text>
                      If you’re a customer, and you want to talk to someone in customer service,
                      drop an email to
                      {" "}<Email address="support@opqo.io" />{" "}
                      and we'll get back to you right away.
                    </Text>
                    <Text className="mt-3">
                      Alternatively, create a ticket in
                      our <a href="https://support.sharptree.io" target="_blank" rel="noopener noreferrer">
                        <u>Support Portal</u></a>
                    </Text>
                  </Box>
                </Row>
              </WidgetWrapper>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default ContactInfo;
