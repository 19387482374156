import React from "react";
import PageTitle from "../components/PageTitle";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import ContactForm from "../sections/contact/ContactForm";
import ContactInfo from "../sections/contact/ContactInfo";

const Contact = (props) => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Seo
          title="Opqo | Contact | Mobile for Maximo"
          description="Opqo is a simpler approach to mobility for Maximo. We would love to hear from you and answer any questions you may have."
          pathname={props.location.pathname}
        />
        <PageTitle title="Contact Us" titleDark />
        <ContactInfo />
        <ContactForm />
      </PageWrapper>
    </>
  );
};
export default Contact;
